import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import UserModule from "@/store/module/user/UserModule";
import mock from "@/components/admin/dialogs/User/mock";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import Waiter from "@/helpers/Waiter";
let CoachForm = class CoachForm extends Vue {
    constructor() {
        super(...arguments);
        this.coach = {
            isCoach: {
                ref: "coach-isCoach",
                type: "checkbox",
                label: "Являюсь тренером",
                value: false,
                onChange: () => {
                    if (this.coach.isCoach.value) {
                        this.coach.category.show = true;
                        this.coach.category.rules.push(Validation.required);
                        (async () => {
                            const ref = "coach-category";
                            await Waiter.waitForRender(this.$refs, ref);
                            //@ts-ignore
                            this.$refs[ref][0].focus();
                            this.pushChangeEvent();
                        })();
                    }
                    else {
                        this.coach.category.show = false;
                        this.coach.category.rules.splice(0);
                        this.pushChangeEvent();
                    }
                },
                showOnlyForSuperAuth: false,
                show: true,
                class: "col-md-6 col-12",
            },
            category: {
                ref: "coach-category",
                type: "select",
                label: "Тренерская категория (звание)",
                value: "",
                items: () => [
                    "Заслуженный тренер России",
                    "Тренер высшей квалификационной категории",
                    "Тренер первой квалификационной категории",
                    "Тренер второй квалификационной категории",
                ],
                onInput: () => {
                    this.pushChangeEvent();
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
            },
        };
        this.fields = Object.values(this.coach);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    setFormData(data) {
        if (!data.category)
            return;
        this.coach.isCoach.value = true;
        this.coach.isCoach.onChange();
        this.coach.category.value = data.category;
    }
    setTestFormData() {
        this.setFormData(mock.coach);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.coach)
            return;
        this.setFormData(this.user.profile.coach);
    }
    isFormValid() {
        if (Validation.validateWithView(this.fields, this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "CoachForm",
            type: "coach",
            payload: UserUpdateRequestDTO.createCoachFromInput({
                category: this.coach.isCoach.value ? this.coach.category.value : null,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "coach";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
CoachForm = __decorate([
    Component({
        components: {},
    })
], CoachForm);
export default CoachForm;

import { __decorate } from "tslib";
import mock from "@/components/admin/dialogs/User/mock";
import Validation from "@/helpers/Validation";
import Waiter from "@/helpers/Waiter";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import UserModule from "@/store/module/user/UserModule";
import Vue from "vue";
import Component from "vue-class-component";
let SportsmanForm = class SportsmanForm extends Vue {
    constructor() {
        super(...arguments);
        this.disciplinesMap = {
            "Классический лук": ["Классический лук"],
            "Блочный лук": ["Блочный лук"],
            "Ачери-биатлон": ["Ачери-кросс", "Инд. гонка", "Масстарт", "Персьют", "Спринт"],
            "3Д": ["Классический лук", "Блочный лук", "Составной лук", "Длинный лук"],
            "Асимметричный лук (Кюдо)": ["Асимметричный лук"],
            ПОДА: [
                "Классический лук - открытый класс",
                "Блочный лук - открытый класс",
                "W1 - открытый класс",
                "Слабовидящие 1",
                "Слабовидящие 23",
            ],
        };
        this.sportsmanProfileDisiplineItems = {
            0: [],
        };
        this.sportsmanProfile = [];
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    createSportsmanProfileItem(index) {
        return {
            disciplineGroup: {
                ref: "sportsmanProfile-disciplineGroup",
                type: "select",
                label: "Группа дисциплин",
                value: "",
                items: () => Object.keys(this.disciplinesMap),
                onInput: function (value) {
                    console.log(this);
                    this.internalPayload.sportsmanProfileDisiplineItems[this.internalPayload.index].splice(0);
                    for (const item of this.internalPayload.disciplinesMap[value]) {
                        this.internalPayload.sportsmanProfileDisiplineItems[this.internalPayload.index].push(item);
                    }
                    this.internalPayload.pushChangeEvent();
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-12 col-12",
                internalPayload: {
                    sportsmanProfileDisiplineItems: this.sportsmanProfileDisiplineItems,
                    disciplinesMap: this.disciplinesMap,
                    index,
                    pushChangeEvent: this.pushChangeEvent.bind(this),
                },
            },
            discipline: {
                ref: "sportsmanProfile-discipline",
                type: "select",
                label: "Дисциплина",
                value: "",
                items: function () {
                    const items = this.internalPayload.sportsmanProfileDisiplineItems[this.internalPayload.index];
                    if (items.length === 1) {
                        this.value = items[0];
                    }
                    return items;
                },
                onInput: () => {
                    this.pushChangeEvent();
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                internalPayload: {
                    index,
                    sportsmanProfileDisiplineItems: this.sportsmanProfileDisiplineItems,
                },
            },
            startYear: {
                ref: "sportsmanProfile-startYear",
                type: "input",
                label: "Год начала занятий",
                value: "",
                placeholder: "0000",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                mask: "####",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            firstCoachSNP: {
                ref: "sportsmanProfile-firstCoachSNP",
                type: "input",
                inputType: "text",
                label: "Ф.И.О первого тренера",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-12 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            personalCoachSNP: {
                ref: "sportsmanProfile-personalCoachSNP",
                type: "input",
                inputType: "text",
                label: "Ф.И.О личного тренера",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-12 col12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
        };
    }
    get fields() {
        let inputs = [];
        for (const sportsmanProfileItem of this.sportsmanProfile) {
            inputs = inputs.concat(Object.values(sportsmanProfileItem));
        }
        return inputs;
    }
    addDiscipline() {
        const index = this.sportsmanProfile.length + 1;
        const item = this.createSportsmanProfileItem(index);
        this.sportsmanProfile.push(item);
        Vue.set(this.sportsmanProfileDisiplineItems, index, []);
        const fields = Object.values(item);
        for (const field of fields) {
            if (field.internalPayload) {
                field.internalPayload.index = this.sportsmanProfile.length - 1;
                field.internalPayload.sportsmanProfileDisiplineItems = this.sportsmanProfileDisiplineItems;
                field.internalPayload.disciplinesMap = this.disciplinesMap;
            }
            field.value = "";
        }
        ;
        (async () => {
            const ref = "sportsmanProfile-disciplineGroup";
            await Waiter.waitForRender(this.$refs, ref, index);
            //@ts-ignore
            this.$refs[ref][this.$refs[ref].length - 1].focus();
            this.pushChangeEvent();
        })();
    }
    removeDiscipline() {
        this.sportsmanProfile.pop();
        this.pushChangeEvent();
    }
    setFormData(data) {
        data.map((sportsmanProfile, index) => {
            if (!this.sportsmanProfile[index]) {
                this.addDiscipline();
            }
            this.sportsmanProfile[index].disciplineGroup.value = sportsmanProfile.disciplineGroup;
            this.sportsmanProfile[index].disciplineGroup.onInput(sportsmanProfile.disciplineGroup);
            this.sportsmanProfile[index].discipline.value = sportsmanProfile.discipline;
            this.sportsmanProfile[index].firstCoachSNP.value = sportsmanProfile.firstCoachSNP
                ? sportsmanProfile.firstCoachSNP
                : "";
            this.sportsmanProfile[index].personalCoachSNP.value = sportsmanProfile.personalCoachSNP
                ? sportsmanProfile.personalCoachSNP
                : "";
            this.sportsmanProfile[index].startYear.value = sportsmanProfile.startYear;
        });
    }
    setTestFormData() {
        this.setFormData(mock.sportsman);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.sportsman)
            return;
        this.setFormData(this.user.profile.sportsman);
    }
    isFormValid() {
        let hasError = false;
        for (const sportsmanProfileItem of this.sportsmanProfile) {
            if (Validation.validateWithView(Object.values(sportsmanProfileItem), this.$refs)) {
                hasError = true;
                break;
            }
            else {
                continue;
            }
        }
        return !hasError;
    }
    pushChangeEvent() {
        const items = [];
        this.sportsmanProfile.map((sportsmanProfileItem) => {
            if (sportsmanProfileItem.disciplineGroup.value === "")
                return;
            items.push({
                disciplineGroup: sportsmanProfileItem.disciplineGroup.value,
                discipline: sportsmanProfileItem.discipline.value,
                firstCoachId: null,
                firstCoachSNP: sportsmanProfileItem.firstCoachSNP.value ? sportsmanProfileItem.firstCoachSNP.value : null,
                personalCoachId: null,
                personalCoachSNP: sportsmanProfileItem.personalCoachSNP.value
                    ? sportsmanProfileItem.personalCoachSNP.value
                    : null,
                startYear: sportsmanProfileItem.startYear.value ? parseInt(sportsmanProfileItem.startYear.value, 10) : null,
            });
        });
        this.$emit("form-changed", {
            name: "SportsmanForm",
            type: "sportsman",
            payload: UserUpdateRequestDTO.createSportsmanFromInput(items),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "sportsman";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
SportsmanForm = __decorate([
    Component({
        components: {},
    })
], SportsmanForm);
export default SportsmanForm;

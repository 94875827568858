import { __decorate } from "tslib";
import mock from "@/components/admin/dialogs/User/mock";
import Validation from "@/helpers/Validation";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import UserModule from "@/store/module/user/UserModule";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
let ParentsForm = class ParentsForm extends Vue {
    constructor() {
        super(...arguments);
        this.parents = {
            motherSNP: {
                ref: "parents-motherSNP",
                type: "input",
                inputType: "text",
                label: "Ф.И.О Матери",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [],
                show: true,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            motherBirthdate: {
                ref: "parents-motherBirthdate",
                type: "datepicker",
                label: "День рождения матери",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: true,
                rules: [],
                show: true,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            fatherSNP: {
                ref: "parents-fatherSNP",
                type: "input",
                inputType: "text",
                label: "Ф.И.О отца",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [],
                show: true,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            fatherBirthdate: {
                ref: "parents-fatherBirthdate",
                type: "datepicker",
                label: "День рождения отца",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: true,
                rules: [],
                show: true,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
        };
        this.fields = Object.values(this.parents);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    setFormData(data) {
        this.parents.motherSNP.value = data.motherSNP ? data.motherSNP : "";
        this.parents.motherBirthdate.value = data.motherBirthdate ? moment(data.motherBirthdate).format("YYYY-MM-DD") : "";
        this.parents.fatherSNP.value = data.fatherSNP ? data.fatherSNP : "";
        this.parents.fatherBirthdate.value = data.fatherBirthdate ? moment(data.fatherBirthdate).format("YYYY-MM-DD") : "";
    }
    setTestFormData() {
        this.setFormData(mock.visaProfile);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.visaProfile)
            return;
        this.setFormData(this.user.profile.visaProfile);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.parents), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "ParentsForm",
            type: "parents",
            payload: UserUpdateRequestDTO.createParentsFromInput({
                motherSNP: this.parents.motherSNP.value ? this.parents.motherSNP.value : null,
                motherBirthdate: this.parents.motherBirthdate.value ? this.parents.motherBirthdate.value : null,
                fatherSNP: this.parents.fatherSNP.value ? this.parents.fatherSNP.value : null,
                fatherBirthdate: this.parents.fatherBirthdate.value ? this.parents.fatherBirthdate.value : null,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "parents";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
ParentsForm = __decorate([
    Component({
        components: {},
    })
], ParentsForm);
export default ParentsForm;

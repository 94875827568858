import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import UserModule from "@/store/module/user/UserModule";
import mock from "@/components/admin/dialogs/User/mock";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import Waiter from "@/helpers/Waiter";
import moment from "moment";
let JudgeForm = class JudgeForm extends Vue {
    constructor() {
        super(...arguments);
        this.judge = {
            isJudge: {
                ref: "judge-isJudge",
                type: "checkbox",
                label: "Являюсь судьей",
                value: false,
                onChange: () => {
                    if (this.judge.isJudge.value) {
                        this.judge.category.rules.push(Validation.required);
                        this.judge.category.show = true;
                        this.judge.order.rules.push(Validation.required);
                        this.judge.order.show = true;
                        this.judge.orderDate.rules.push(Validation.required);
                        this.judge.orderDate.show = true;
                        (async () => {
                            const ref = "judge-category";
                            await Waiter.waitForRender(this.$refs, ref);
                            //@ts-ignore
                            this.$refs[ref][0].focus();
                            this.pushChangeEvent();
                        })();
                    }
                    else {
                        this.judge.category.rules.splice(0);
                        this.judge.category.show = false;
                        this.judge.order.rules.splice(0);
                        this.judge.order.show = false;
                        this.judge.orderDate.rules.splice(0);
                        this.judge.orderDate.show = false;
                        this.pushChangeEvent();
                    }
                },
                showOnlyForSuperAuth: false,
                show: true,
                class: "col-md-6 col-12",
            },
            category: {
                ref: "judge-category",
                type: "select",
                label: "Категория спортивного судьи",
                value: "",
                items: () => ["Всероссийская", "Первая", "Вторая", "Третья", "Юный судья", "Без категории"],
                onInput: () => {
                    this.pushChangeEvent();
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
            },
            order: {
                ref: "judge-order",
                type: "input",
                label: "№ приказа о присвоении спортивной судейской категории",
                value: "",
                placeholder: "Приказ от 29 марта 2024 г. № 85нг",
                onInput: () => {
                    this.pushChangeEvent();
                },
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
            },
            orderDate: {
                ref: "judge-orderDate",
                type: "datepicker",
                label: "Дата приказа о присвоении спортивной судейской категории",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.judge.orderDate.isOpened = !this.judge.orderDate.isOpened;
                    this.pushChangeEvent();
                },
            },
        };
        this.fields = Object.values(this.judge);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    setFormData(data) {
        console.log(data);
        if (!data.category)
            return;
        this.judge.isJudge.value = true;
        this.judge.isJudge.onChange();
        this.judge.category.value = data.category;
        this.judge.order.value = data.order;
        this.judge.orderDate.value = data.orderDate ? moment(data.orderDate).format("YYYY-MM-DD") : "";
    }
    setTestFormData() {
        this.setFormData(mock.judge);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.judge)
            return;
        this.setFormData(this.user.profile.judge);
    }
    isFormValid() {
        if (Validation.validateWithView(this.fields, this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "JudgeForm",
            type: "judge",
            payload: UserUpdateRequestDTO.createJudgeFromInput({
                category: this.judge.isJudge.value ? this.judge.category.value : null,
                order: this.judge.isJudge.value ? this.judge.order.value : null,
                orderDate: this.judge.isJudge.value ? this.judge.orderDate.value : null,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "judge";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
JudgeForm = __decorate([
    Component({
        components: {},
    })
], JudgeForm);
export default JudgeForm;

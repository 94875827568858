import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import UserModule from "@/store/module/user/UserModule";
import mock from "@/components/admin/dialogs/User/mock";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
let SocialForm = class SocialForm extends Vue {
    constructor() {
        super(...arguments);
        this.social = {
            medicialInsurance: {
                ref: "social-medicialInsurance",
                type: "input",
                label: "Номер страхового полиса ОМС",
                value: "",
                placeholder: "0".repeat(16),
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                mask: "#".repeat(16),
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            TIN: {
                ref: "social-TIN",
                type: "input",
                label: "ИНН",
                value: "",
                placeholder: "0".repeat(12),
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                mask: "#".repeat(12),
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            INIPA: {
                ref: "social-INIPA",
                type: "input",
                label: "СНИЛС",
                value: "",
                placeholder: "123-456-789 12",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                mask: "###-###-### ##",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
        };
        this.fields = Object.values(this.social);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    setFormData(data) {
        this.social.medicialInsurance.value = data.medicialInsurance;
        this.social.TIN.value = data.TIN;
        this.social.INIPA.value = data.INIPA;
    }
    setTestFormData() {
        this.setFormData(mock.social);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.social)
            return;
        this.setFormData(this.user.profile.social);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.social), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "SocialForm",
            type: "social",
            payload: UserUpdateRequestDTO.createSocialFromInput({
                medicialInsurance: this.social.medicialInsurance.value,
                TIN: this.social.TIN.value,
                INIPA: this.social.INIPA.value,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "social";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
SocialForm = __decorate([
    Component({
        components: {},
    })
], SocialForm);
export default SocialForm;

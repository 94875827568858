import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import UserModule from "@/store/module/user/UserModule";
import mock from "@/components/admin/dialogs/User/mock";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import countries from "@/data/countries";
import moment from "moment";
import Waiter from "@/helpers/Waiter";
let AboardForm = class AboardForm extends Vue {
    constructor() {
        super(...arguments);
        this.aboard = {
            hasAboardPassport: {
                ref: "aboard-hasAboardPassport",
                type: "checkbox",
                label: "Есть заграничный паспорт",
                value: false,
                onChange: () => {
                    const keys = [
                        "surname",
                        "name",
                        "patronymic",
                        "number",
                        "authority",
                        "issueDate",
                        "expirationDate",
                    ];
                    if (this.aboard.hasAboardPassport.value) {
                        console.log(`set required validation`);
                        keys.map((key) => {
                            //@ts-ignore
                            this.aboard[key].show = true;
                            //@ts-ignore
                            this.aboard[key].rules.push(Validation.required);
                        });
                        (async () => {
                            await Waiter.waitForRender(this.$refs, "aboard-surname");
                            //@ts-ignore
                            this.$refs["aboard-surname"][0].focus();
                            this.pushChangeEvent();
                        })();
                    }
                    else {
                        keys.map((key) => {
                            //@ts-ignore
                            this.aboard[key].show = false;
                            //@ts-ignore
                            this.aboard[key].rules.splice(0);
                        });
                        this.pushChangeEvent();
                    }
                },
                showOnlyForSuperAuth: false,
                show: true,
                class: "col-md-12 col-12",
            },
            surname: {
                ref: "aboard-surname",
                type: "input",
                inputType: "text",
                label: "Фамилия",
                value: "",
                placeholder: "Введите фамилию как в загран. паспорте",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            name: {
                ref: "aboard-name",
                type: "input",
                inputType: "text",
                label: "Имя",
                value: "",
                placeholder: "Введите имя как в загран. паспорте",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            patronymic: {
                ref: "aboard-patronymic",
                type: "input",
                inputType: "text",
                label: "Отчество",
                value: "",
                placeholder: "Введите отчество как в загран. паспорте",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            number: {
                ref: "aboard-number",
                type: "input",
                inputType: "text",
                label: "Номер",
                value: "",
                placeholder: "0".repeat(9),
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                mask: "#".repeat(9),
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            authority: {
                ref: "aboard-authority",
                type: "input",
                inputType: "text",
                label: "Кем выдан",
                value: "",
                placeholder: "Введите кем выдан загран. паспорт",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-12 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            issueDate: {
                ref: "aboard-issueDate",
                type: "datepicker",
                label: "Дата выдачи",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: true,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            expirationDate: {
                ref: "aboard-expirationDate",
                type: "datepicker",
                label: "Дата истечения срока",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: true,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            vistedCountries: {
                ref: "aboard-visitedCountries",
                type: "select",
                label: "Посещенные страны",
                items: () => countries.map((c) => c.name_ru),
                onInput: () => {
                    this.pushChangeEvent();
                },
                filter: (item, queryText, itemText) => {
                    return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
                },
                value: [],
                placeholder: "Выберите посещенные страны",
                showOnlyForSuperAuth: false,
                rules: [],
                show: true,
                class: "col-md-12 col-12",
                noData: "",
                isMultiple: true,
                search: "",
                onSearch: () => { },
                isAutocomplete: true,
            },
            hasUsaVisa: {
                ref: "aboard-hasUsaVisa",
                type: "checkbox",
                label: "Есть виза в США",
                value: false,
                onChange: () => {
                    this.pushChangeEvent();
                },
                showOnlyForSuperAuth: false,
                show: true,
                class: "col-md-6 col-12",
            },
            hasBiometrics: {
                ref: "aboard-hasBiometrics",
                type: "checkbox",
                label: "Сдавалась биометрия",
                value: false,
                onChange: () => {
                    if (this.aboard.hasBiometrics.value) {
                        this.aboard.biometicsIssueDate.show = true;
                        this.aboard.biometicsIssueDate.rules.push(Validation.required);
                    }
                    else {
                        this.aboard.biometicsIssueDate.show = false;
                        this.aboard.biometicsIssueDate.rules.splice(0);
                    }
                    this.pushChangeEvent();
                },
                showOnlyForSuperAuth: false,
                show: true,
                class: "col-md-6 col-12",
            },
            biometicsIssueDate: {
                ref: "aboard-biometicsIssueDate",
                type: "datepicker",
                label: "Дата сдачи биометрии",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: true,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
        };
        this.fields = Object.values(this.aboard);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    setFormData(data) {
        if (data.hasAboardPassport) {
            this.aboard.hasAboardPassport.value = true;
            this.aboard.hasAboardPassport.onChange();
            this.aboard.surname.value = data.surname;
            this.aboard.name.value = data.name;
            this.aboard.patronymic.value = data.patronymic;
            this.aboard.number.value = data.number;
            this.aboard.authority.value = data.authority;
            this.aboard.issueDate.value = moment(data.issueDate).format("YYYY-MM-DD");
            this.aboard.expirationDate.value = moment(data.expirationDate).format("YYYY-MM-DD");
        }
        this.aboard.vistedCountries.value = data.visitedCountries;
        this.aboard.hasUsaVisa.value = data.hasUsaVisa;
        this.aboard.hasBiometrics.value = data.hasBiometrics;
        this.aboard.hasBiometrics.onChange();
        this.aboard.biometicsIssueDate.value = data.biometricsIssueDate
            ? moment(data.biometricsIssueDate).format("YYYY-MM-DD")
            : "";
    }
    setTestFormData() {
        this.setFormData(mock.aboard);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.aboard)
            return;
        this.setFormData(this.user.profile.aboard);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.aboard), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "AboardForm",
            type: "aboard",
            payload: UserUpdateRequestDTO.createAboardFromInput({
                hasAboardPassport: this.aboard.hasAboardPassport.value,
                surname: this.aboard.hasAboardPassport.value ? this.aboard.surname.value : null,
                name: this.aboard.hasAboardPassport.value ? this.aboard.name.value : null,
                patronymic: this.aboard.hasAboardPassport.value ? this.aboard.patronymic.value : null,
                number: this.aboard.hasAboardPassport.value ? this.aboard.number.value : null,
                authority: this.aboard.hasAboardPassport.value ? this.aboard.authority.value : null,
                issueDate: this.aboard.hasAboardPassport.value ? this.aboard.issueDate.value : null,
                expirationDate: this.aboard.hasAboardPassport.value ? this.aboard.expirationDate.value : null,
                visitedCountries: this.aboard.vistedCountries.value || [],
                hasUsaVisa: this.aboard.hasUsaVisa.value,
                hasBiometrics: this.aboard.hasBiometrics.value,
                biometricsIssueDate: this.aboard.hasBiometrics.value ? this.aboard.biometicsIssueDate.value : null,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "aboard";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
AboardForm = __decorate([
    Component({
        components: {},
    })
], AboardForm);
export default AboardForm;

import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import UserModule from "@/store/module/user/UserModule";
import mock from "@/components/admin/dialogs/User/mock";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
let EquipForm = class EquipForm extends Vue {
    constructor() {
        super(...arguments);
        this.equip = {
            size: {
                ref: "equip-size",
                type: "select",
                label: "Размер одежды",
                value: "",
                items: () => [40, 42, 44, 46, 48, 49, 50, 52, 54, 56, 58, 60, 62, 64].map((i) => String(i)),
                onInput: () => {
                    this.pushChangeEvent();
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
            },
            height: {
                ref: "equip-height",
                type: "input",
                inputType: "number",
                label: "Ваш рост",
                value: "",
                placeholder: "165",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            shoeSize: {
                ref: "equip-shoeSize",
                type: "select",
                label: "Размер обуви",
                value: "",
                items: () => [
                    34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 45,
                    46,
                ].map((i) => String(i)),
                onInput: () => {
                    this.pushChangeEvent();
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
            },
        };
        this.fields = Object.values(this.equip);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    setFormData(data) {
        this.equip.size.value = data.size;
        this.equip.height.value = data.height;
        this.equip.shoeSize.value = data.shoeSize;
    }
    setTestFormData() {
        this.setFormData(mock.equip);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.equip)
            return;
        this.setFormData(this.user.profile.equip);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.equip), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "EquipForm",
            type: "equip",
            payload: UserUpdateRequestDTO.createEquipFromInput({
                size: this.equip.size.value,
                height: this.equip.height.value,
                shoeSize: this.equip.shoeSize.value,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "equip";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async save() {
        if (!this.isFormValid())
            return;
        const input = {
            size: this.equip.size.value || null,
            height: this.equip.height.value || null,
            shoeSize: this.equip.shoeSize.value || null,
        };
        await this.$store.dispatch(UserModule.types.actions.UPDATE_USER, { user: { profile: { equip: input } } });
        this.$notify({
            title: "Данные экипировки успешно обновлены!",
            type: "success",
        });
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
EquipForm = __decorate([
    Component({
        components: {},
    })
], EquipForm);
export default EquipForm;

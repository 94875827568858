export default class PassportValidator {
    static isValidSerial(value) {
        return !!value.match(/^[0-9]{4}$/);
    }
    static serialRule(value) {
        return PassportValidator.isValidSerial(value) || "Неверный формат серийного номера";
    }
    static isValidNumber(value) {
        return !!value.match(/^[0-9]{3}-[0-9]{3}$/);
    }
    static numberRule(value) {
        return PassportValidator.isValidNumber(value) || "Неверный формат номера";
    }
    static isValidAuthorityCode(value) {
        return !!value.match(/^[0-9]{3}-[0-9]{3}$/);
    }
    static authorityCodeRule(value) {
        return PassportValidator.isValidAuthorityCode(value) || "Неверный формат кода подразделения";
    }
}

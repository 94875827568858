import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import UserModule from "@/store/module/user/UserModule";
import mock from "@/components/admin/dialogs/User/mock";
import moment from "moment";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import Waiter from "@/helpers/Waiter";
let FamilyStatusForm = class FamilyStatusForm extends Vue {
    constructor() {
        super(...arguments);
        this.familyStatus = {
            partnershipType: {
                ref: "familyStatus-partnershipStatus",
                type: "select",
                label: "Семейное положение",
                value: "",
                items: () => ["Холост/Незамужем", "Женат/Замужем", "Разведен(-а)"],
                onInput: () => {
                    if (this.familyStatus.partnershipType.value === "Женат/Замужем") {
                        this.familyStatus.partnerSNP.show = true;
                        this.familyStatus.partnerSNP.rules.push(Validation.required);
                        this.familyStatus.partnerCitizenship.show = true;
                        this.familyStatus.partnerCitizenship.rules.push(Validation.required);
                        this.familyStatus.partnerBirthdate.show = true;
                        this.familyStatus.partnerBirthdate.rules.push(Validation.required);
                        if (this.user.gender === "male") {
                            this.familyStatus.partnerMaidenName.show = true;
                        }
                        ;
                        (async () => {
                            await Waiter.waitForRender(this.$refs, "familyStatus-partnerSNP");
                            //@ts-ignore
                            this.$refs["familyStatus-partnerSNP"][0].focus();
                            this.pushChangeEvent();
                        })();
                    }
                    else {
                        this.familyStatus.partnerSNP.show = false;
                        this.familyStatus.partnerSNP.rules.splice(0);
                        this.familyStatus.partnerCitizenship.show = false;
                        this.familyStatus.partnerCitizenship.rules.splice(0);
                        this.familyStatus.partnerBirthdate.show = false;
                        this.familyStatus.partnerBirthdate.rules.splice(0);
                        this.familyStatus.partnerMaidenName.show = false;
                        this.pushChangeEvent();
                    }
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
            },
            partnerSNP: {
                ref: "familyStatus-partnerSNP",
                type: "input",
                inputType: "text",
                label: "Ф.И.О супруга/супруги",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            partnerMaidenName: {
                ref: "familyStatus-partnerMaidenName",
                type: "input",
                inputType: "text",
                label: "Девичья фамилия супруги",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            partnerCitizenship: {
                ref: "familyStatus-partnerCitizenship",
                type: "input",
                inputType: "text",
                label: "Гражданство супруга/супруги",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            partnerBirthdate: {
                ref: "familyStatus-partnerBirthdate",
                type: "datepicker",
                label: "День рождения супруга/супруги",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: true,
                rules: [],
                show: false,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
        };
        this.fields = Object.values(this.familyStatus);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    setFormData(data, gender) {
        this.familyStatus.partnershipType.value = data.partnershipType;
        if (data.partnershipType === "Женат/Замужем") {
            this.familyStatus.partnerSNP.value = data.partnerSNP;
            this.familyStatus.partnerSNP.show = true;
            this.familyStatus.partnerCitizenship.value = data.partnerCitizenship;
            this.familyStatus.partnerCitizenship.show = true;
            this.familyStatus.partnerBirthdate.value = moment(data.partnerBirthdate).format("YYYY-MM-DD");
            this.familyStatus.partnerBirthdate.show = true;
            if (gender === "male") {
                this.familyStatus.partnerMaidenName.value = data.partnerMaidenName;
                this.familyStatus.partnerMaidenName.show = true;
            }
        }
    }
    setTestFormData() {
        this.setFormData(mock.familyStatus, "male");
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.familyStatus)
            return;
        this.setFormData(this.user.profile.familyStatus, this.user.gender);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.familyStatus), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "FamilyStatusForm",
            type: "familyStatus",
            payload: UserUpdateRequestDTO.createFamilyStatusFromInput({
                partnershipType: this.familyStatus.partnershipType.value,
                partnerSNP: this.familyStatus.partnershipType.value === "Женат/Замужем" ? this.familyStatus.partnerSNP.value : null,
                partnerMaidenName: this.familyStatus.partnershipType.value === "Женат/Замужем" &&
                    this.user.gender === "male" &&
                    this.familyStatus.partnerMaidenName.value
                    ? this.familyStatus.partnerMaidenName.value
                    : null,
                partnerCitizenship: this.familyStatus.partnershipType.value === "Женат/Замужем" && this.familyStatus.partnerCitizenship.value
                    ? this.familyStatus.partnerCitizenship.value
                    : null,
                partnerBirthdate: this.familyStatus.partnershipType.value === "Женат/Замужем" && this.familyStatus.partnerBirthdate.value
                    ? this.familyStatus.partnerBirthdate.value
                    : null,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "familyStatus";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
FamilyStatusForm = __decorate([
    Component({
        components: {},
    })
], FamilyStatusForm);
export default FamilyStatusForm;

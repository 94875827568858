export default {
    userId: 4540,
    inNationalTeamSinceYear: 2007,
    passport: {
        userId: 4540,
        serial: "1111",
        number: "111-111",
        authorityCode: "500-179",
        authority: "ТП № 1 МРО УФМС РОССИИ ПО МОСКОВСКОЙ ОБЛ. В ГОРОДСКОМ ПОСЕЛЕНИИ ЩЕЛКОВО",
        birthPlace: "ГОР. ФРЯЗИНО МОСКОВСКОЙ ОБЛ.",
        issueDate: "2015-05-07",
    },
    employment: {
        userId: 4540,
        education: "Неполное высшее",
        educationPlace: "РТУ МИРЭА",
    },
    living: {
        userId: 4540,
        address: "Московская обл, г Щёлково, мкр Финский, д 3А",
        index: 141107,
    },
    social: {
        userId: 4540,
        medicialInsurance: "1111111111111111",
        TIN: "111111111111",
        INIPA: "111-111-111 11",
    },
    equip: {
        userId: 4540,
        size: "52",
        height: "170",
        shoeSize: "42.5",
    },
    aboard: {
        userId: 4540,
        hasAboardPassport: true,
        surname: "Павлович",
        name: "Сергей",
        patronymic: "Александрович",
        number: "312313211",
        authority: "тест",
        issueDate: "2024-03-06",
        expirationDate: "2024-02-25",
        visitedCountries: ["Австралия", "Австрия"],
        hasUsaVisa: true,
        hasBiometrics: true,
        biometricsIssueDate: "2024-01-09",
    },
    visaProfile: {
        userId: 4540,
        fatherSNP: "ФИО отца",
        fatherBirthdate: "2024-03-16",
    },
    familyStatus: {
        userId: 4540,
        partnershipType: "Женат/Замужем",
        partnerSNP: "фио супруги",
        partnerMaidenName: "туст",
        partnerCitizenship: "Российское",
        partnerBirthdate: "2000-05-10",
    },
    sportsman: [
        {
            userId: 4540,
            disciplineGroup: "Классический лук",
            discipline: "Классический лук",
            firstCoachSNP: "a",
            personalCoachSNP: "b",
            startYear: 2007,
        },
        {
            userId: 4540,
            disciplineGroup: "Блочный лук",
            discipline: "Блочный лук",
            firstCoachSNP: "c",
            personalCoachSNP: "k",
            startYear: 2010,
        },
    ],
    coach: {
        category: "Тренер первой квалификационной категории",
    },
    judge: {
        category: "Первая",
        order: "Приказ от 15 марта 2023 г. № 51нг",
        orderDate: "2005-05-20",
    },
};

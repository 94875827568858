import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import Validation from "@/helpers/Validation";
import PassportValidator from "@/components/admin/dialogs/User/PassportValidator";
import DaDataSuggestions from "@/service/DaDataSuggestions";
import UserModule from "@/store/module/user/UserModule";
import mock from "@/components/admin/dialogs/User/mock";
import moment from "moment";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import deepEqualInAnyOrder from "@/helpers/deepEqualInAnyOrder";
let PassportForm = class PassportForm extends Vue {
    constructor() {
        super(...arguments);
        this.daData = new DaDataSuggestions();
        this.pickedRegion = null;
        this.cache = {
            prevAuthorityCode: "",
        };
        this.authoritySuggestions = [];
        this.passport = {
            serial: {
                ref: "passport-serial",
                type: "input",
                label: "Серия",
                value: "",
                placeholder: "0000",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                mask: "####",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            number: {
                ref: "passport-number",
                type: "input",
                label: "Номер",
                value: "",
                placeholder: "000-000",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                mask: "###-###",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
            authorityCode: {
                ref: "passport-authority-code",
                type: "input",
                label: "Код подразделения",
                value: "",
                placeholder: "000-000",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                mask: "###-###",
                onInput: async (value, manual = false) => {
                    if (!PassportValidator.isValidAuthorityCode(value))
                        return;
                    if (this.cache.prevAuthorityCode === value)
                        return;
                    this.cache.prevAuthorityCode = value;
                    if (!manual) {
                        this.pushChangeEvent();
                    }
                    await this.getAuthorities();
                },
            },
            issueDate: {
                ref: "passport-issueDate",
                type: "datepicker",
                label: "Дата выдачи",
                value: "",
                isOpened: false,
                showOnlyForSuperAuth: true,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
                onInput: () => {
                    this.passport.issueDate.isOpened = !this.passport.issueDate.isOpened;
                    this.pushChangeEvent();
                },
            },
            authority: {
                ref: "passport-authority",
                type: "select",
                label: "Кем выдан",
                value: "",
                items: () => this.authoritySuggestions,
                onInput: () => {
                    this.pushChangeEvent();
                },
                isMultiple: false,
                noData: "Список подразделений пуст. Укажите корректный код подразделения.",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-12 col-12",
            },
            birthPlace: {
                ref: "passport-birthPlace",
                type: "input",
                label: "Место рождения",
                value: "",
                placeholder: "Введите ваше место рождения",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-12 col-12",
                onInput: () => {
                    this.pushChangeEvent();
                },
            },
        };
        this.fields = Object.values(this.passport);
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    async getAuthorities() {
        try {
            const data = await this.daData.getAuthorities(this.passport.authorityCode.value);
            this.authoritySuggestions.splice(0);
            for (const suggestion of data.suggestions) {
                this.authoritySuggestions.push(suggestion.value);
            }
        }
        catch (e) {
            return this.$notify({
                title: "Ошибка загрузки подразделений",
                type: "error",
            });
        }
    }
    setFormData(data) {
        this.passport.serial.value = data.serial;
        this.passport.number.value = data.number;
        this.passport.authorityCode.value = data.authorityCode;
        this.passport.authorityCode.onInput(data.authorityCode, true).then(() => {
            this.passport.authority.value = data.authority;
        });
        this.passport.birthPlace.value = data.birthPlace;
        this.passport.issueDate.value = data.issueDate ? moment(data.issueDate).format("YYYY-MM-DD") : "";
    }
    setTestFormData() {
        this.setFormData(mock.passport);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.passport)
            return;
        this.setFormData(this.user.profile.passport);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.passport), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "PassportForm",
            type: "passport",
            payload: UserUpdateRequestDTO.createPassportFromInput({
                serial: this.passport.serial.value || null,
                number: this.passport.number.value || null,
                authorityCode: this.passport.authorityCode.value || null,
                authority: this.passport.authority.value || null,
                birthPlace: this.passport.birthPlace.value || null,
                issueDate: this.passport.issueDate.value || null,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "passport";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async save() {
        if (!this.isFormValid())
            return;
        console.log(this.user.isNationalTeamMember && !this.user.profile);
        if (this.user.isNationalTeamMember && !this.user.profile) {
            return this.$notify({
                title: "Возможность частичного обновления анкеты появится после её полного заполнения и одобрения",
                type: "warning",
                duration: 3000,
            });
        }
        const hasAnyValue = this.passport.serial.value ||
            this.passport.number.value ||
            this.passport.authorityCode.value ||
            this.passport.authority.value ||
            this.passport.birthPlace.value ||
            this.passport.issueDate.value;
        if (!hasAnyValue) {
            return this.$notify({
                title: "Не обнаружено изменений в данных",
                text: "Для успешной отравки заявки - внесите изменения",
                type: "warning",
                duration: 3000,
            });
        }
        const newPassportData = {
            serial: this.passport.serial.value || null,
            number: this.passport.number.value || null,
            authorityCode: this.passport.authorityCode.value || null,
            authority: this.passport.authority.value || null,
            birthPlace: this.passport.birthPlace.value || null,
            issueDate: this.passport.issueDate.value || null,
        };
        if (this.user.profile && this.user.profile.passport) {
            const diff = deepEqualInAnyOrder({
                serial: this.user.profile.passport.serial,
                number: this.user.profile.passport.number,
                authorityCode: this.user.profile.passport.authorityCode,
                authority: this.user.profile.passport.authority,
                birthPlace: this.user.profile.passport.birthPlace,
                issueDate: this.user.profile.passport.issueDate,
            }, newPassportData);
            if (Object.keys(diff).length > 0) {
                return this.$notify({
                    title: "Не обнаружено изменений в данных",
                    text: "Для успешной отравки заявки - внесите изменения",
                    type: "warning",
                    duration: 3000,
                });
            }
        }
        await this.$store.dispatch(UserModule.types.actions.UPDATE_REQUEST, {
            request: new UserUpdateRequestDTO({
                passport: UserUpdateRequestDTO.createPassportFromInput(newPassportData),
            }, null, []),
        });
        this.$notify({
            title: "Заявка на обновление профиля успешно отправлена!",
            type: "success",
            duration: 3000,
        });
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
PassportForm = __decorate([
    Component({
        components: {},
    })
], PassportForm);
export default PassportForm;

import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import UserProfile from "@/components/user/profile/UserProfile.vue";
let UserProfilePage = class UserProfilePage extends Vue {
};
UserProfilePage = __decorate([
    Component({
        components: {
            UserProfile,
        },
    })
], UserProfilePage);
export default UserProfilePage;

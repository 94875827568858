import { __decorate } from "tslib";
import mock from "@/components/admin/dialogs/User/mock";
import Validation from "@/helpers/Validation";
import Waiter from "@/helpers/Waiter";
import DaDataSuggestions from "@/service/DaDataSuggestions";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import UserModule from "@/store/module/user/UserModule";
import Vue from "vue";
import Component from "vue-class-component";
let EmploymentForm = class EmploymentForm extends Vue {
    constructor() {
        super(...arguments);
        this.daData = new DaDataSuggestions();
        this.companySuggestions = {
            workPlace: [],
            educationPlace: [],
        };
        this.employment = {
            education: {
                ref: "employment-education",
                type: "select",
                label: "Образование",
                value: "",
                items: () => ["Неполное среднее", "Среднее", "Неполное высшее", "Высшее"],
                onInput: () => {
                    this.pushChangeEvent();
                },
                isMultiple: false,
                noData: "",
                showOnlyForSuperAuth: false,
                disabled: false,
                rules: [Validation.required],
                show: true,
                class: "col-md-6 col-12",
            },
            pickType: {
                ref: "employment-pickType",
                type: "checkbox",
                label: "Я работаю",
                value: false,
                onChange: () => {
                    if (this.employment.pickType.value) {
                        this.employment.educationPlace.show = false;
                        this.employment.workPlace.show = true;
                    }
                    else {
                        this.employment.educationPlace.show = true;
                        this.employment.workPlace.show = false;
                    }
                },
                showOnlyForSuperAuth: false,
                show: true,
                class: "col-md-6 col-12",
            },
            educationPlace: {
                ref: "employment-educationPlace",
                type: "select",
                label: "Место учебы",
                items: () => this.companySuggestions.educationPlace,
                onInput: () => {
                    this.pushChangeEvent();
                },
                filter: () => true,
                value: "",
                placeholder: "Введите ваше место учебы",
                showOnlyForSuperAuth: false,
                rules: [],
                show: true,
                class: "col-md-12 col-12",
                noData: "Начните вводить название",
                isMultiple: false,
                search: "",
                onSearch: async () => {
                    await this.getCompanies("educationPlace", this.employment.educationPlace);
                },
                isAutocomplete: true,
            },
            workPlace: {
                ref: "employment-workPlace",
                type: "select",
                label: "Место работы",
                items: () => this.companySuggestions.workPlace,
                onInput: () => {
                    this.pushChangeEvent();
                },
                filter: () => true,
                value: "",
                placeholder: "Введите ваше место работы",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-12 col-12",
                noData: "Начните вводить название",
                isMultiple: false,
                search: "",
                onSearch: async () => {
                    await this.getCompanies("workPlace", this.employment.workPlace);
                },
                isAutocomplete: true,
            },
        };
        this.fields = Object.values(this.employment);
        this.getCompanyThrottle = new Map();
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    async getCompanies(type, input) {
        if (!input.search)
            return;
        if (this.getCompanyThrottle.has(type))
            return;
        this.getCompanyThrottle.set(type, true);
        await Waiter.wait(1000);
        if (input.search) {
            await this.$getCompanies(type, input.search);
        }
        this.getCompanyThrottle.delete(type);
    }
    async $getCompanies(type, query) {
        try {
            const data = await this.daData.getCompanies(query);
            this.companySuggestions[type].splice(0);
            for (const suggestion of data.suggestions) {
                this.companySuggestions[type].push(suggestion.value);
            }
        }
        catch (e) {
            console.log(e);
            return this.$notify({
                title: "Ошибка загрузки компаний",
                type: "error",
            });
        }
    }
    setFormData(data) {
        this.employment.education.value = data.education;
        if (data.workPlace) {
            this.employment.pickType.value = true;
            this.employment.workPlace.search = data.workPlace;
            this.employment.workPlace.onSearch().then(() => {
                this.employment.workPlace.value = data.workPlace;
            });
        }
        else if (data.educationPlace) {
            this.employment.educationPlace.search = data.educationPlace;
            this.employment.educationPlace.onSearch().then(() => {
                this.employment.educationPlace.value = data.educationPlace;
            });
        }
    }
    setTestFormData() {
        this.setFormData(mock.employment);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.employment)
            return;
        this.setFormData(this.user.profile.employment);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.employment), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "EmploymentForm",
            type: "employment",
            payload: UserUpdateRequestDTO.createEmploymentFromInput({
                education: this.employment.education.value,
                workPlace: this.employment.workPlace.value,
                educationPlace: this.employment.educationPlace.value,
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "employment";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
EmploymentForm = __decorate([
    Component({
        components: {},
    })
], EmploymentForm);
export default EmploymentForm;

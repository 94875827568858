import { __decorate } from "tslib";
import mock from "@/components/admin/dialogs/User/mock";
import Validation from "@/helpers/Validation";
import Waiter from "@/helpers/Waiter";
import DaDataSuggestions from "@/service/DaDataSuggestions";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import UserModule from "@/store/module/user/UserModule";
import Vue from "vue";
import Component from "vue-class-component";
let LivingForm = class LivingForm extends Vue {
    constructor() {
        super(...arguments);
        this.daData = new DaDataSuggestions();
        this.addressSuggestions = {
            livingAddress: [],
        };
        this.living = {
            address: {
                ref: "living-address",
                type: "select",
                label: "Адрес",
                items: () => this.addressSuggestions.livingAddress,
                itemText: "address",
                itemValue: "address",
                filter: () => true,
                value: "",
                placeholder: "Введите ваше адрес",
                showOnlyForSuperAuth: false,
                rules: [Validation.required],
                show: true,
                class: "col-12 col-md-6",
                noData: "Начните вводить адрес",
                isMultiple: false,
                search: "",
                onSearch: async () => {
                    await this.getAddresses("livingAddress", this.living.address);
                },
                onInput: (value) => {
                    if (value) {
                        this.living.index.value = String(value.postalCode);
                    }
                    this.pushChangeEvent();
                },
                isAutocomplete: true,
                returnObject: true,
            },
            index: {
                ref: "living-index",
                type: "input",
                label: "",
                value: "",
                placeholder: "",
                showOnlyForSuperAuth: false,
                rules: [],
                show: false,
                class: "col-md-12 col-12",
            },
        };
        this.fields = Object.values(this.living);
        this.getAddressThrottle = new Map();
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    async getAddresses(type, input) {
        if (!input.search)
            return;
        if (this.getAddressThrottle.has(type))
            return;
        this.getAddressThrottle.set(type, true);
        await Waiter.wait(1000);
        if (input.search) {
            await this.$getAddresses(type, input.search);
        }
        this.getAddressThrottle.delete(type);
    }
    async $getAddresses(type, query) {
        try {
            const data = await this.daData.getAddresses(query);
            this.addressSuggestions[type].splice(0);
            for (const suggestion of data.suggestions) {
                this.addressSuggestions[type].push({ address: suggestion.value, postalCode: suggestion.data.postal_code });
            }
        }
        catch (e) {
            console.log(e);
            return this.$notify({
                title: "Ошибка загрузки адресов",
                type: "error",
            });
        }
    }
    setFormData(data) {
        this.living.address.search = data.address;
        this.living.address.onSearch().then(() => {
            //@ts-ignore
            this.living.address.value = { address: data.address, postalCode: data.index };
            this.living.index.value = String(data.index);
        });
    }
    setTestFormData() {
        this.setFormData(mock.living);
    }
    setUserFormData() {
        if (!this.user.profile)
            return;
        if (!this.user.profile.living)
            return;
        this.setFormData(this.user.profile.living);
    }
    isFormValid() {
        if (Validation.validateWithView(Object.values(this.living), this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        const input = {
            // @ts-ignore
            address: this.living.address.value ? this.living.address.value.address : null,
            // @ts-ignore
            index: this.living.address.value ? this.living.address.value.postalCode : null,
        };
        this.$emit("form-changed", {
            name: "LivingForm",
            type: "living",
            payload: UserUpdateRequestDTO.createLivingFromInput(input),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "living";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setUserFormData();
        this.pushChangeEvent();
        this.bindMock();
    }
};
LivingForm = __decorate([
    Component({
        components: {},
    })
], LivingForm);
export default LivingForm;
